import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const User: FC<IconSvgProps> = ({ color, clss }) => {
    const styles = {
        svg: {
            verticalAlign: 'middle',
            overflow: 'hidden',
        },
    };

    return (
        <svg style={styles.svg} className={clss} width="22" height="22" viewBox="0 0 21 34">
            <path
                fill={color}
                d="M10.4,12.6c1.7,0,3.3-0.7,4.4-1.8C16,9.6,16.7,8,16.7,6.3S16.1,3,14.9,1.8S12.1-0.1,10.4,0C7,0,4.2,2.8,4.1,6.3 C4.2,9.8,6.9,12.5,10.4,12.6z M10.3,2.8c1.9,0,3.4,1.5,3.5,3.4c0,1.9-1.5,3.5-3.4,3.5C8.5,9.8,7,8.2,6.9,6.3 C6.9,4.4,8.4,2.8,10.3,2.8z"
            />
            <path
                fill={color}
                d="M18.2,15.8c-0.9-0.9-2.1-1.4-3.3-1.5c-3-0.2-6.1-0.2-9.2,0c-2.8,0.2-5,2.6-5,5.5c0,0.7,0,1.4,0,2.2v1.5 c0,1.6,0,3.4,0,5c0,1.5,0,2.9,0,4v0.6h2.7V20.7c0-2.2,1.4-3.5,3.7-3.5h6.7c2.2,0,3.5,1.4,3.5,3.5v12.6l1-0.8 c0.1-0.1,0.2-0.2,0.4-0.3c0.6-0.5,1.3-1,1.4-2c0-1.5,0-3,0-4.5c0-1.9,0-3.7,0-5.6C20.1,18.5,19.4,16.9,18.2,15.8z"
            />
        </svg>
    );
};

export default User;
